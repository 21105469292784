import styles from "./Cards.module.css";
import phone from "../../assets/img/svg/phone.svg";
import people from "../../assets/img/svg/people.svg";
import prancheta from "../../assets/img/svg/prancheta.svg";
import graph from "../../assets/img/svg/graph.svg";

import CarouselSoftware from "../../components/CarouselSoftware/CarouselSoftware"

const Cards = () => {
  return (
    <div className={styles.containerCards}>
      <div className={styles.mainCards}>
        <h1>
          Nossos softwares são pensados e<br /> desenvolvidos para ajudar nossos
          clientes.
        </h1>
        <hr />
        <CarouselSoftware/>
        <div className={styles.cardsSection}>
          <div className={styles.containerIndividualCard}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="43"
              viewBox="0 0 85 43"
              fill="none"
              className={styles.iconTriangleTop}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.426392 0L0 0.426392L42.4264 42.8528L84.8528 0.426392L84.4264 0H0.426392Z"
                fill="#F5B41C"
              />
            </svg>
            <div className={styles.individualCard}>
              <div className={styles.containerIcon}>
                <img src={phone} alt="icon" />
              </div>
              <p>
                Empresa moderna, preocupada com o atendimento aos nossos
                clientes. Trabalhamos com atendimento humanizado, sem dispensar
                os recursos tecnológicos.
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="43"
              viewBox="0 0 85 43"
              fill="none"
              className={styles.iconTriangleBottom}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M84.4264 42.8528L84.8528 42.4264L42.4264 -1.89678e-05L3.72763e-08 42.4264L0.426392 42.8528L84.4264 42.8528Z"
                fill="#F5B41C"
              />
            </svg>
          </div>
          <div className={styles.containerIndividualCard}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="43"
              viewBox="0 0 85 43"
              fill="none"
              className={styles.iconTriangleTop}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.426392 0L0 0.426392L42.4264 42.8528L84.8528 0.426392L84.4264 0H0.426392Z"
                fill="#F5B41C"
              />
            </svg>
            <div className={styles.individualCard}>
              <div className={styles.containerIcon}>
                <img src={people} alt="icon" />
              </div>
              <p>
                Nosso time está preparado para trazer soluções sob medida com
                retorno financeiro e operacional sobre o investimento (ROI).
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="43"
              viewBox="0 0 85 43"
              fill="none"
              className={styles.iconTriangleBottom}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M84.4264 42.8528L84.8528 42.4264L42.4264 -1.89678e-05L3.72763e-08 42.4264L0.426392 42.8528L84.4264 42.8528Z"
                fill="#F5B41C"
              />
            </svg>
          </div>
          <div className={styles.containerIndividualCard}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="43"
              viewBox="0 0 85 43"
              fill="none"
              className={styles.iconTriangleTop}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.426392 0L0 0.426392L42.4264 42.8528L84.8528 0.426392L84.4264 0H0.426392Z"
                fill="#F5B41C"
              />
            </svg>
            <div className={styles.individualCard}>
              <div className={styles.containerIcon}>
                <img src={prancheta} alt="icon" />
              </div>
              <p>
                Realizar serviços altamente profissionais de tecnologia da
                informação com base nas melhores práticas de atendimento e
                produtos de mercado.
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="43"
              viewBox="0 0 85 43"
              fill="none"
              className={styles.iconTriangleBottom}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M84.4264 42.8528L84.8528 42.4264L42.4264 -1.89678e-05L3.72763e-08 42.4264L0.426392 42.8528L84.4264 42.8528Z"
                fill="#F5B41C"
              />
            </svg>
          </div>
          <div className={styles.containerIndividualCard}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="43"
              viewBox="0 0 85 43"
              fill="none"
              className={styles.iconTriangleTop}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.426392 0L0 0.426392L42.4264 42.8528L84.8528 0.426392L84.4264 0H0.426392Z"
                fill="#F5B41C"
              />
            </svg>
            <div className={styles.individualCard}>
              <div className={styles.containerIcon}>
                <img src={graph} alt="icon" />
              </div>
              <p>
                Nossa missão combina trabalho e talento. Nós desejamos alcançar
                resultados extraordinários ajudando nossos clientes.
              </p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="85"
              height="43"
              viewBox="0 0 85 43"
              fill="none"
              className={styles.iconTriangleBottom}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M84.4264 42.8528L84.8528 42.4264L42.4264 -1.89678e-05L3.72763e-08 42.4264L0.426392 42.8528L84.4264 42.8528Z"
                fill="#F5B41C"
              />
            </svg>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Cards;
