import styles from "./AboutTwo.module.css";
import imagem from "../../assets/img/svg/elipse.svg";

const AboutTwo = () => {
  return (
    <div className={styles.containerAbout}>
      <div className={styles.mainAbout}>
        <div className={styles.cards}>
          <div className={styles.cardIndividual}>
            <h2>Alejandro Miguel - Founder & CEO</h2>
            <div className={styles.textAndImage}>
              <p>
                Idealizador das nossas soluções, formado em Engenharia mecânica,
                com mais de 15 anos de experiência em empreendedorismo,
                trabalhou com vários modelos de negócio online e offline assim
                como no mercado de varejo. Com um coração apaixonado por
                tecnologia e gestão de pessoas, decidiu idealizar e realizar
                business company, colocando em prática toda a sua expertise nos
                principais pilares da tecnologia: dados, segurança e
                desenvolvimento. Atualmente na AMW GROUP elabora estratégias de
                crescimento, rentabilidade e desenvolve Software de alta
                performance.
              </p>
              <img src={imagem} alt="" />
            </div>
            <hr />
          </div>
          <div className={styles.cardIndividual}>
            <h2>Arianne Silva - VP Executive & Sócia</h2>
            <div className={styles.textAndImage}>
              <p>
                Profissional com carreira consolidada no departamento comercial,
                com mais de 10 anos de experiência administrativa, focada no
                desenvolvimento de estratégias. Realiza gestão de canais de
                vendas direta/indireta, visando o aumento dos resultados da
                companhia. Hoje como VP Executiva e Sócia gerencia todo
                departamento administrativo, traçando estratégias de budget e
                controle. Bacharel em Administração de empresas, responsável
                pela tomada de decisão para o crescimento do negócio, tendo
                visibilidade das ações e necessidades.
              </p>
              <img src={imagem} alt="" />
            </div>
            <hr />
          </div>
          <div className={styles.cardIndividual}>
            <h2>Adriano Belisario - CTO</h2>
            <div className={styles.textAndImage}>
              <p>
                Hoje como nosso Diretor de Tecnologia coloca em prática toda sua
                expertise no processo de transformação, digitalização e inovação
                do grupo, desenvolvendo soluções de automação, IA(Inteligência
                artificial). Formado em sistemas da informação, conta com mais
                de 11 anos de experiência na área de desenvolvimento Web com
                conhecimento em Full Stack Developer, Zend PHP, Adobe Certified
                Professional | Adobe Commerce Developer (Magento), TechLead,
                PHP, Frameworks, Javascript + Frameworks, NODE.js, HTML/CSS, CTO
                (Company / Product Owner).
              </p>
              <img src={imagem} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
