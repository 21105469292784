import styles from "./Footer.module.css";
import logo from "../../assets/img/svg/logoFooter.svg"
import { LiaLinkedinIn } from "react-icons/lia";
import { ImFacebook } from "react-icons/im";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";

const Footer = () => {
  function toTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <div className={styles.mainFooter}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" onClick={toTop} />
      </div>

      <div className={styles.copyright}>
        <p>
          Copyright 2023 &copy; AMW GROUP&nbsp; 
          <br className={styles.br} />-<br className={styles.br} />
          &nbsp;Política de privacidade
        </p>
        <p>Todos os direitos reservados</p>
      </div>

      <div className={styles.redesSociais}>
        <a
          href="https://www.linkedin.com/company/amwgroup1/mycompany/"
          target="_blank"
          rel="noreferrer"
        >
          <LiaLinkedinIn className={styles.iconFooter} />
        </a>
        <a
          href="https://www.facebook.com/people/AMW-GROUP/100095237560281/"
          target="_blank"
          rel="noreferrer"
        >
          <ImFacebook className={styles.iconFooter} />
        </a>
        <a
          href="https://www.instagram.com/amwgroup.oficial/"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineInstagram className={styles.iconFooter} />
        </a>
        <a
          href="https://www.youtube.com/channel/UC_h5aepBEHlOUnv95xjA_aw"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillYoutube className={styles.iconFooter} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
