import styles from "./AboutOne.module.css";
import { LiaLinkedinIn } from "react-icons/lia";
import { ImFacebook } from "react-icons/im";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";

const AboutOne = () => {
  return (
    <div className={styles.containerAbout}>
      <div className={styles.mainAbout}>
        <h1>Saiba mais sobre nós!</h1>
        <p>
          A AMW Group é uma empresa especializada em proporcionar excelência no
          desempenho para organizações que buscam se destacar como líderes na
          era da transformação digital. Com o uso de tecnologia de ponta e
          inteligência artificial, estamos comprometidos em impulsionar o
          sucesso e a eficiência em todas as áreas de atuação de sua empresa,
          seja ela voltada para o mercado online ou offline.
        </p>
        <hr />
        <div className={styles.containerVideo}>
          <iframe
            src="https://www.youtube.com/embed/CQ9SWY9jyR0"
            frameborder="0"
            allowfullscreen
          ></iframe>

          <div className={styles.containerIcons}>
            <a
              href="https://www.linkedin.com/company/amwgroup1/mycompany/"
              target="_blank"
              rel="noreferrer"
            >
              <LiaLinkedinIn className={styles.icon} />
            </a>
            <a
              href="https://www.facebook.com/people/AMW-GROUP/100095237560281/"
              target="_blank"
              rel="noreferrer"
            >
              <ImFacebook className={styles.icon} />
            </a>
            <a
              href="https://www.instagram.com/amwgroup.oficial/"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineInstagram className={styles.icon} />
            </a>
            <a
              href="https://www.youtube.com/channel/UC_h5aepBEHlOUnv95xjA_aw"
              target="_blank"
              rel="noreferrer"
            >
              <AiFillYoutube className={styles.icon} />
            </a>
          </div>
        </div>

        <hr />
        <p>
          Nossa abordagem se concentra em fornecer as soluções digitais mais
          avançadas para atender às necessidades específicas do seu negócio.
          Desenvolvemos software e plataformas online personalizados, com o
          objetivo de compreender profundamente sua persona, aprimorar a
          experiência do usuário durante a navegação, segmentar e personalizar
          campanhas de marketing de maneira mais precisa e, assim, envolver sua
          persona de forma mais eficaz, resultando em um aumento significativo
          da conversão com um investimento mais eficiente.
        </p>
        <p>
          Ao combinar essas soluções, a AMW GROUP disponibiliza um conjunto
          completo de recursos e funcionalidades destinados a otimizar e
          impulsionar o crescimento do seu negócio. Estamos comprometidos em ser
          seu parceiro de confiança na jornada rumo ao sucesso digital e estamos
          prontos para ajudá-lo a alcançar seus objetivos de negócios de forma
          estratégica e eficaz. Contate-nos hoje para descobrir como podemos
          colaborar para o sucesso sustentável de sua empresa.
        </p>
      </div>
    </div>
  );
};

export default AboutOne;
