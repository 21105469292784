import React from "react";
import Cards from "../CardsSoftware/Cards";
import Carousel from "../Carousel/Carousel";
import Header from "../Header/Header";
import Section from "../whyAmw/Section";
import SectionForms from "../sectionForms/Section";
import Footer from "../../components/Footer/Footer";
import CookieBar from "../../components/CookieBar/CookieBar";

const Default = () => {
  return (
    <div className="App">
      <header>
        <Header />
        <CookieBar/>
      </header>
      <article>
        <Cards />
        <Section />
      </article>
      <aside>
        <Carousel />
        <SectionForms />
      </aside>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Default;
