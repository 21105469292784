import Navbar from "../../components/Navbar/NavbarComponent";
import styles from "./Header.module.css";

const Header = () => {
  function toBottom() {
    window.scrollTo(0,  document.body.scrollHeight);
  }
  return (
    <div className={styles.containerHeader}>
      <Navbar />
      <video
        autoPlay
        loop
        muted
        src="/futuristic_lines_1_1.webm"
        type="video/webm"
        className={styles.videoHeader}
      ></video>
      <div className={styles.mainHeader}>
        <h1>
          <span>Ecossistema Digital</span>
          <br />
          para Business Intelligence
        </h1>
        <h2>A Transformação digital do seu negócio em 360°</h2>
        <p>Sim, nós temos o que seu negócio precisa!</p>
        <p>A AMW Group possui mais soluções para ajudar você!</p>
        <button className={styles.btnHeader} onClick={toBottom}>Entrar em contato</button>
      </div>
    </div>
  );
};

export default Header;
