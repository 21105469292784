import Forms from "../../components/Forms/Forms";
import styles from "./Section.module.css";

const Section = () => {
  return (
    <div className={styles.containerForms}>
      <Forms />
    </div>
  );
};

export default Section;
