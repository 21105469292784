import { BiLogoWhatsapp } from "react-icons/bi";
import logoAzul from "../../assets/img/png/Logo-SVG 1.png";
import { Link } from "react-router-dom";
import "./NavbarComponent.css";

function NavbarComponent() {
  return (
    <div className="navbarStructure">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={logoAzul} alt="icon" className="logoNav" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <p className="verticalLine"></p>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className="nav-link" aria-current="page">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://ecomtraffega.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-com
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="http://traffega.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Traffega
                </a>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  Sobre
                </Link>
              </li>
              <li className="nav-item lastCont">
                <a
                  className="nav-link"
                  aria-disabled="true"
                  id="contate"
                  href="https://api.whatsapp.com/send?phone=5511992089747&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20a%20AMW%20Group!"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contate-nos
                  <BiLogoWhatsapp className="logoWhatsapp" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavbarComponent;
