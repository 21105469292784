import styles from "./About.module.css";
import NavbarComponent from "../../components/Navbar/NavbarComponent";
import Footer from "../../components/Footer/Footer";
import AboutOne from "../../components/SectionAboutOne/AboutOne";
import AboutTwo from "../../components/SectionAboutTwo/AboutTwo";

const About = () => {
  return (
    <>
      <div className={styles.mainAbout}>
        <div className={styles.navbar}>
          <NavbarComponent />
        </div>
        <div className={styles.contentAbout}>
          <AboutOne />
          <AboutTwo />
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </>
  );
};

export default About;
