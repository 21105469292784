import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./CarouselSoftware.css";
import phone from "../../assets/img/svg/phone.svg";
import people from "../../assets/img/svg/people.svg";
import prancheta from "../../assets/img/svg/prancheta.svg";
import graph from "../../assets/img/svg/graph.svg";

// const handleDragStart = (e) => e.preventDefault();

const items = [
  {
    img: phone,
    description:
      "Empresa moderna, preocupada com o atendimento aos nossos clientes. Trabalhamos com atendimento humanizado, sem dispensar os recursos tecnológicos.",
  },
  {
    img: people,
    description:
      "Nosso time está preparado para trazer soluções sob medida com retorno financeiro e operacional sobre o investimento (ROI).",
  },
  {
    img: prancheta,
    description:
      " Realizar serviços altamente profissionais de tecnologia da informação com base nas melhores práticas de atendimento e produtos de mercado.",
  },
  {
    img: graph,
    description:
      " Nossa missão combina trabalho e talento. Nós desejamos alcançar resultados extraordinários ajudando nossos clientes.",
  },
];

const Card = ({ img, description }) => (
  <div className="cardClientBox">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="43"
      viewBox="0 0 85 43"
      fill="none"
      className="iconTriangleTop"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.426392 0L0 0.426392L42.4264 42.8528L84.8528 0.426392L84.4264 0H0.426392Z"
        fill="#F5B41C"
      />
    </svg>
    <div className="clientDiv">
      <img src={img} alt="icon" className="clientIcon" />
      <p className="clientParagraph">{description}</p>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="43"
      viewBox="0 0 85 43"
      fill="none"
      className="iconTriangleBottom"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.4264 42.8528L84.8528 42.4264L42.4264 -1.89678e-05L3.72763e-08 42.4264L0.426392 42.8528L84.4264 42.8528Z"
        fill="#F5B41C"
      />
    </svg>
  </div>
);

const Gallery = () => {
  const carouselRef = useRef(null);

  const responsive = {
    0: { items: 1 },
    836: { items: 2 },
  };

  const renderedItems = items.map((item, index) => (
    <Card
      key={index}
      img={item.img}
      description={item.description}
      index={item.index}
    />
  ));

  // console.log(Card.index);
  return (
    <AliceCarousel
      mouseTracking
      items={renderedItems}
      ref={carouselRef}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlayInterval={3000}
    />
  );
};

export default Gallery;
