import "./Carousel.css";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { useRef } from "react";
import { Autoplay } from "swiper/modules";

import {IoIosArrowBack} from 'react-icons/io'
import {IoIosArrowForward} from "react-icons/io"


export default function Carousel() {
  const swiperRef = useRef();
  return (
    <div className="containerCarousel">
      <div className="mainCarousel">
        <h1>
          No que <span>Acreditamos?</span>
        </h1>
        <hr />
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: false,
          }}
          // onSlideChange={(e) => console.log("testando transição")}
          infinite="true"
          loop={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          // onSwiper={(swiper) => console.log(swiper)}
          className="mySwiper"
          breakpoints={{
            1920: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          <SwiperSlide>
            <div className="contentCard">
              <div className="iconCard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M78.2006 34.9613L72.7619 28.6428C71.7222 27.4431 70.8824 25.2037 70.8824 23.6041V16.8058C70.8824 12.5669 67.4033 9.08772 63.1643 9.08772H56.366C54.8064 9.08772 52.527 8.24793 51.3273 7.20819L45.0089 1.76956C42.2496 -0.589852 37.7307 -0.589852 34.9314 1.76956L28.6528 7.24818C27.4531 8.24793 25.1737 9.08772 23.6141 9.08772H16.6958C12.4569 9.08772 8.97775 12.5669 8.97775 16.8058V23.6441C8.97775 25.2037 8.13796 27.4431 7.13821 28.6428L1.73956 35.0013C-0.579855 37.7606 -0.579855 42.2395 1.73956 44.9988L7.13821 51.3572C8.13796 52.5569 8.97775 54.7963 8.97775 56.3559V63.1942C8.97775 67.4332 12.4569 70.9123 16.6958 70.9123H23.6141C25.1737 70.9123 27.4531 71.7521 28.6528 72.7918L34.9714 78.2304C37.7307 80.5899 42.2496 80.5899 45.0489 78.2304L51.3673 72.7918C52.567 71.7521 54.8064 70.9123 56.406 70.9123H63.2043C67.4433 70.9123 70.9224 67.4332 70.9224 63.1942V56.3959C70.9224 54.8363 71.7622 52.5569 72.8019 51.3572L78.2406 45.0388C80.56 42.2795 80.56 37.7206 78.2006 34.9613ZM56.606 32.4419L37.2908 51.7571C36.731 52.3169 35.9711 52.6369 35.1713 52.6369C34.3716 52.6369 33.6117 52.3169 33.0519 51.7571L23.3741 42.0795C22.2144 40.9198 22.2144 39.0003 23.3741 37.8406C24.5339 36.6809 26.4534 36.6809 27.6131 37.8406L35.1713 45.3987L52.367 28.2029C53.5267 27.0432 55.4463 27.0432 56.606 28.2029C57.7657 29.3626 57.7657 31.2822 56.606 32.4419Z"
                    fill="#303866"
                  />
                </svg>
              </div>
              <h4 className="titleCardCarousel">Melhorias</h4>
              <p>
                Não ficamos presos a regras antigas só porque estão dando certo.
                Estamos sempre inovando para continuar fazendo o melhor.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contentCard">
              <div className="iconCard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M40 0C17.92 0 0 17.92 0 40C0 62.08 17.92 80 40 80C62.08 80 80 62.08 80 40C80 17.92 62.08 0 40 0ZM18.4 36.92C19.08 32.16 21.2 27.88 24.52 24.52C32.52 16.56 45.12 16.12 53.68 23.08V19.28C53.68 17.64 55.04 16.28 56.68 16.28C58.32 16.28 59.68 17.64 59.68 19.28V29.96C59.68 31.6 58.32 32.96 56.68 32.96H46C44.36 32.96 43 31.6 43 29.96C43 28.32 44.36 26.96 46 26.96H49C42.8 22.64 34.24 23.24 28.72 28.76C26.32 31.16 24.8 34.28 24.28 37.76C24.08 39.24 22.8 40.32 21.32 40.32C21.16 40.32 21.04 40.32 20.88 40.28C19.32 40.08 18.16 38.56 18.4 36.92ZM55.48 55.48C51.2 59.76 45.6 61.88 40 61.88C35.12 61.88 30.28 60.16 26.28 56.92V60.68C26.28 62.32 24.92 63.68 23.28 63.68C21.64 63.68 20.28 62.32 20.28 60.68V50C20.28 48.36 21.64 47 23.28 47H33.96C35.6 47 36.96 48.36 36.96 50C36.96 51.64 35.6 53 33.96 53H30.96C37.16 57.32 45.72 56.72 51.24 51.2C53.64 48.8 55.16 45.68 55.68 42.2C55.92 40.56 57.4 39.4 59.08 39.64C60.72 39.88 61.84 41.4 61.64 43.04C60.92 47.88 58.8 52.16 55.48 55.48Z"
                    fill="#303866"
                  />
                </svg>
              </div>
              <h4 className="titleCardCarousel">Atualizações</h4>
              <p>
                Nossa metodologia inovadora de comercialização, desenvolvimento
                e implantação em módulos garante que nossos clientes encontrem a
                melhor solução para seu negócio.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contentCard">
              <div className="iconCard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M56.76 0H23.24C8.68 0 0 8.68 0 23.24V56.72C0 71.32 8.68 80 23.24 80H56.72C71.28 80 79.96 71.32 79.96 56.76V23.24C80 8.68 71.32 0 56.76 0ZM28.44 59.6C28.44 60.72 27.56 61.6 26.44 61.6H15.28C14.16 61.6 13.28 60.72 13.28 59.6V41.12C13.28 38.6 15.32 36.56 17.84 36.56H26.44C27.56 36.56 28.44 37.44 28.44 38.56V59.6ZM47.56 59.6C47.56 60.72 46.68 61.6 45.56 61.6H34.4C33.28 61.6 32.4 60.72 32.4 59.6V22.96C32.4 20.44 34.44 18.4 36.96 18.4H43.04C45.56 18.4 47.6 20.44 47.6 22.96V59.6H47.56ZM66.72 59.6C66.72 60.72 65.84 61.6 64.72 61.6H53.56C52.44 61.6 51.56 60.72 51.56 59.6V45.4C51.56 44.28 52.44 43.4 53.56 43.4H62.16C64.68 43.4 66.72 45.44 66.72 47.96V59.6Z"
                    fill="#303866"
                  />
                </svg>
              </div>
              <h4 className="titleCardCarousel">Investimentos</h4>
              <p>
                Todos os dias nós investimos muito em tecnologia, desempenho,
                usabilidade, backup, segurança e melhorias no suporte e
                atendimento.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contentCard">
              <div className="iconCard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="65"
                  height="80"
                  viewBox="0 0 65 80"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M39.4509 0.790495C48.7734 2.83106 56.8157 9.03277 60.9768 17.4351C69.059 33.6796 61.2569 51.3245 48.8535 59.6868V62.4876C48.8935 63.5679 48.9335 65.5684 47.4131 67.1289C46.2928 68.2492 44.6523 68.8493 42.4917 68.8493H21.9661C20.3256 68.8493 18.245 68.6893 16.8446 67.2489C15.4843 65.8485 15.5243 64.128 15.5643 63.0077V59.6868C6.20171 53.4051 0 42.5621 0 32.1992C0 22.3965 4.3612 13.2339 12.0433 7.11224C19.6854 0.95054 29.6882 -1.3701 39.4509 0.790495ZM44.4062 79.88C44.6463 79.96 44.9264 80 45.1665 80C46.3668 80 47.4871 79.1998 47.8872 77.9594C48.3273 76.479 47.4471 74.9586 45.9667 74.5185C36.9242 71.9578 27.4016 71.9578 18.359 74.5185C16.8786 74.9186 15.9983 76.479 16.4385 77.9594C16.8386 79.4398 18.399 80.2801 19.8794 79.88C27.9217 77.5993 36.364 77.5993 44.4062 79.88Z"
                    fill="#303866"
                  />
                </svg>
              </div>
              <h4 className="titleCardCarousel">Simplicidade</h4>
              <p>
                A solução pode ser complexa, mas precisa ser simples. Quanto
                mais simples de entender e usar, mais valor o sistema terá para
                você e para o seu negócio.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contentCard">
              <div className="iconCard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M78.2006 34.9613L72.7619 28.6428C71.7222 27.4431 70.8824 25.2037 70.8824 23.6041V16.8058C70.8824 12.5669 67.4033 9.08772 63.1643 9.08772H56.366C54.8064 9.08772 52.527 8.24793 51.3273 7.20819L45.0089 1.76956C42.2496 -0.589852 37.7307 -0.589852 34.9314 1.76956L28.6528 7.24818C27.4531 8.24793 25.1737 9.08772 23.6141 9.08772H16.6958C12.4569 9.08772 8.97775 12.5669 8.97775 16.8058V23.6441C8.97775 25.2037 8.13796 27.4431 7.13821 28.6428L1.73956 35.0013C-0.579855 37.7606 -0.579855 42.2395 1.73956 44.9988L7.13821 51.3572C8.13796 52.5569 8.97775 54.7963 8.97775 56.3559V63.1942C8.97775 67.4332 12.4569 70.9123 16.6958 70.9123H23.6141C25.1737 70.9123 27.4531 71.7521 28.6528 72.7918L34.9714 78.2304C37.7307 80.5899 42.2496 80.5899 45.0489 78.2304L51.3673 72.7918C52.567 71.7521 54.8064 70.9123 56.406 70.9123H63.2043C67.4433 70.9123 70.9224 67.4332 70.9224 63.1942V56.3959C70.9224 54.8363 71.7622 52.5569 72.8019 51.3572L78.2406 45.0388C80.56 42.2795 80.56 37.7206 78.2006 34.9613ZM56.606 32.4419L37.2908 51.7571C36.731 52.3169 35.9711 52.6369 35.1713 52.6369C34.3716 52.6369 33.6117 52.3169 33.0519 51.7571L23.3741 42.0795C22.2144 40.9198 22.2144 39.0003 23.3741 37.8406C24.5339 36.6809 26.4534 36.6809 27.6131 37.8406L35.1713 45.3987L52.367 28.2029C53.5267 27.0432 55.4463 27.0432 56.606 28.2029C57.7657 29.3626 57.7657 31.2822 56.606 32.4419Z"
                    fill="#303866"
                  />
                </svg>
              </div>
              <h4 className="titleCardCarousel">Transparência</h4>
              <p>
                Nada de termos complicados, nem contratos com letras miúdas.
                Nossa relação com os clientes é sempre transparente, simples e
                objetiva.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contentCard">
              <div className="iconCard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="61"
                  height="80"
                  viewBox="0 0 61 80"
                  fill="none"
                >
                  <path
                    d="M35.3831 0.028648C34.2111 -0.177631 33.1799 0.757601 33.1799 1.94773V13.4532C33.1799 14.251 33.6662 14.9534 34.3298 15.3966C36.3535 16.7487 37.6844 19.057 37.6844 21.6836V29.7272C37.6844 33.8698 34.306 37.2884 30.1636 37.2884C25.9809 37.2884 22.6026 33.8698 22.6026 29.7272V21.6836C22.6026 19.0562 23.9631 16.7473 25.9966 15.3954C26.661 14.9537 27.1472 14.251 27.1472 13.4532V1.95031C27.1472 0.75925 26.1156 -0.176384 24.9429 0.0304574C18.7112 1.12926 13.1344 4.12522 8.84798 8.41163C3.37832 13.8813 0 21.4423 0 29.7272V49.8364C0 66.4465 13.5535 80 30.1636 80C46.7736 80 60.3271 66.4465 60.3271 49.8364V29.7272C60.3271 14.8848 49.5205 2.51654 35.3831 0.028648Z"
                    fill="#303866"
                  />
                </svg>
              </div>
              <h4 className="titleCardCarousel">Usabilidade</h4>
              <p>
                Quando desenvolvemos nossos softwares, pensamos na mais fácil
                usabilidade e desempenho, mediante a padronização das telas.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div>
        {" "}
        <button onClick={() => swiperRef.current?.slidePrev()} className="buttonPrev"><IoIosArrowBack/></button>
        <button onClick={() => swiperRef.current?.slideNext()} className="buttonNext"><IoIosArrowForward/></button>
      </div>
        <hr />
      </div>
    </div>
  );
}
