import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About/About';
import Default from './pages/DefaultPage/Default';
import ScrollToTop from "./components/ScrollTop/ScrollTop"
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton'

function App() {
  return (
    <BrowserRouter>
    <WhatsAppButton/>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Default />} />
        <Route path='/about' element={<About />} />
        <Route path='policies' element={<PrivacyPolicy/>} />
        <Route path='*' element={<Default />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
